.lottie-wrapper{
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    
    &__svg-container{
        width: 45%;
    }
    &__text{
        color: var(--mlook-app-danger);
        font-size: 2.5rem;
        margin-bottom: 1rem;
        padding: .125rem .75rem;
        background-color: #F3F5FD;
        border-radius: .5rem;
        
    }
}