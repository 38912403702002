:root{
  --mlook-app-body-bg:#33465F;
  --mlook-app-body-color:#fff;

  --mlook-app-danger:#F35B75;/*#D9004B;*/
  
  --mlook-app-body-row-color:#fff;
  --mlook-app-body-row-even-bg:#eaeaea;
  

  /*--chime-dark-blue: #041B37;
  --chime-basic-red: #D9004B;
  --chime-navy-blue: #89BBF4;
  --chime-basic-green: #04CC0C;
  --chime-basic-gray: #808C9B;
  --chime-basic-warning: #ffc107;*/
}
body{
  background-color: var(--mlook-app-body-bg);
  color: var(--mlook-app-body-color);
}

.mlook-app-container{
  --mlook-talk-body1:var(--mlook-app-body-row-color);
  --mlook-talk-body2:var(--mlook-app-body-row-even-bg);

  .jme-airport__body-row.talk-now{

  }
  .jme-airport__body-row.talk-now:nth-child(2n){
    background-color: var(--mlook-app-body-row-even-bg);
  }
  .jme-airport__page-bar>div{
    height: .4rem;
  }
  .jme-airport__session-agenda.has-paging .session-agenda-item{
    padding-right: .5rem;
  }
  .jme-mlook .roomview-container{
    .jme-mlook__has-running-talk-view {
      .jme-mlook__running-talk .jme-mlook__running-talk-meta{
        padding: 0.5rem 0.25rem 0.2rem .25rem;
        .talk-info__title{
          padding-left: .5rem;
        }
      }
    }
  }

}